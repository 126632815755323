import axios, { AxiosInstance } from 'axios'

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $axios: AxiosInstance;
    }
}

const functionsBaseUrl = process.env.DEV ?
    `http://localhost:5001/${process.env.FIREBASE_PROJECT_ID}/europe-west1` :
    `https://europe-west1-${process.env.FIREBASE_PROJECT_ID}.cloudfunctions.net`

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const dinnerApi = axios.create({ baseURL: functionsBaseUrl })

export { dinnerApi }
