import { defineStore } from 'pinia'

import {
    approveOrderApi,
    createOrderApi,
    validateAddressApi,
    validateDiscountApi,
    validateOrderApi,
    validateTeamApi
} from '@api/checkout.api'

import { FetchState } from '@constants/fetchState.enum'
import { TeamSize } from '@constants/teamSize.enum'

import {
    createDefaultDinnerOrder,
    createDefaultDinnerPerson,
    DinnerOrder,
    DinnerOrderValidateError,
    DinnerOrderValidateResponse,
    TicketType
} from '@models/dto/dinnerOrder'
import { Ticket } from '@models/dto/ticket.model'

import { useEventStore } from '@stores/event.store'

interface DinnerOrderStoreState {
  orderValidationErrors: DinnerOrderValidateError[];
  fetchState: FetchState;
  order: DinnerOrder;
}

const defaultDinnerOrderStoreState: DinnerOrderStoreState = {
    orderValidationErrors: [],
    fetchState: FetchState.INIT,
    order: createDefaultDinnerOrder()
}

export const useDinnerOrderStore = defineStore('dinnerOrder', {
    state: (): DinnerOrderStoreState => defaultDinnerOrderStoreState,
    actions: {
        async executeWithLoading(action: () => Promise<any>) {
            this.fetchState = FetchState.FETCHING
            try {
                const result = await action()

                this.fetchState = FetchState.SUCCESS

                return result
            } catch (e) {
                console.error(e)
                this.fetchState = FetchState.ERROR
            }
        },
        async createOrder(locale: string): Promise<DinnerOrderValidateResponse> {
            return await this.executeWithLoading(async () => {
                this.order.language = locale

                const { data } = await createOrderApi(this.order)

                return data
            })
        },
        async approveOrder(paypalOrderId: string) {
            return await this.executeWithLoading(async () => {
                const { data } = await approveOrderApi(paypalOrderId, this.order.eventId)

                return data
            })
        },
        async validateTeam() {
            await this.executeWithLoading(async () => {
                if (this.order.team.teamType === TeamSize.ALONE) {
                    this.order.team.secondPerson = createDefaultDinnerPerson()
                }

                const { data } = await validateTeamApi(this.order)
                const { order, errors } = data

                this.order = order
                this.orderValidationErrors = errors || []
            })
        },
        async validateAddress() {
            await this.executeWithLoading(async () => {
                const { data } = await validateAddressApi(this.order)
                const { order, errors } = data

                this.order = order
                this.orderValidationErrors = errors || []
            })
        },
        async validateOrder() {
            await this.executeWithLoading(async () => {
                const { data } = await validateOrderApi(this.order)
                const { order, errors } = data

                this.order = order
                this.orderValidationErrors = errors || []
            })
        },
        async validateDiscount(discountCode: string) {
            this.fetchState = FetchState.FETCHING
            try {
                const { data } = await validateDiscountApi(this.order, discountCode)
                const { order, errors } = data

                this.order = order
                this.orderValidationErrors = errors || []

                return { hasDiscountError: false }
            } catch (e) {
                console.error(e)

                return { hasDiscountError: true }
            } finally {
                this.fetchState = FetchState.SUCCESS
            }
        },
        async resetOrder() {
            this.order = createDefaultDinnerOrder()
            await useEventStore().init()
        },
        resetErrors() {
            this.orderValidationErrors = []
        },
        setEventId(eventId: string) {
            this.order.eventId = eventId
        },
        setTickets(tickets: Ticket[]) {
            // for each which assigns the ticket to the correct place
            tickets.forEach((currentTicket: Ticket) => {
                if (currentTicket.type === 'REQUIRED') {
                    this.order.tickets.requiredTicket = {
                        price: currentTicket.price,
                        quantity: 0,
                        type: TicketType.REQUIRED
                    }
                } else {
                    this.order.tickets.optionalTickets = {
                        price: currentTicket.price,
                        quantity: 0,
                        type: currentTicket.type
                    }
                }
            })
        }
    },
    getters: {
        isFetchingOrder: (state) => state.fetchState === FetchState.FETCHING,
        isAlone: (state) => state.order.team.teamType === TeamSize.ALONE,
        hasFetchingError: (state) => state.fetchState === FetchState.ERROR
    }
})
