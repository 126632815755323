<script lang="ts" setup>
import { useEventStore } from "@stores/event.store"

void useEventStore().init()
</script>

<template>
    <suspense>
        <router-view />
    </suspense>
</template>
