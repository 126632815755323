import { RouteRecordRaw } from 'vue-router'

const childRoutes = [
    {
        name: 'home',
        path: '',
        component: () => import('@pages/IndexPage.vue')
    },
    {
        name: 'imprint',
        path: 'imprint',
        component: () => import('@pages/ImprintPage.vue')
    },
    {
        name: 'privacy',
        path: 'privacy',
        component: () => import('@pages/PrivacyPage.vue')
    },
    {
        name: 'newsletterUnsubscribe',
        path: 'goodbye',
        component: () => import('@pages/UnsubscribeFromNewsletterPage.vue')
    },
    {
        name: 'terms',
        path: 'terms',
        component: () => import('@pages/TermsConditionsPage.vue')
    }
]

const registrationOpenRoutes = [
    {
        name: 'ticketsBuying',
        path: 'tickets',
        component: () => import('@pages/checkout/CheckoutPage.vue')
    },
    {
        name: 'checkoutSuccess',
        path: 'success',
        component: () => import('@pages/checkout/CheckoutSuccess.vue')
    },
    {
        name: 'checkoutError',
        path: 'error',
        component: () => import('@pages/checkout/CheckoutError.vue')
    },
    {
        name: 'event',
        path: 'event/:uriPath',
        component: () => import('@pages/EventDetailPage.vue')
    }
]

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        component: () => import('@layouts/MainLayout.vue'),
        children: [
            ...childRoutes,
            ...registrationOpenRoutes
        ]
    },
    {
        path: '/:catchAll(.*)*',
        component: () => import('@pages/ErrorNotFoundPage.vue')
    }
]


export default routes
