import { getDateFromFirebaseTimestamp } from '@api/converters/firebaseTimestamp.converter'

import { AfterPartyLocationDto } from '@models/dto/afterpartyLocation.dto'
import { HoppingEventDto } from '@models/dto/hoppingEvent.dto'
import { AfterPartyLocation } from '@models/entities/afterpartyLocation.model'
import { HoppingEvent } from '@models/entities/hoppingEvent.model'


const convertAfterPartyLocationDtoToEntity = (dto: AfterPartyLocationDto): AfterPartyLocation => ({
    ...dto,
    partyStartDate: getDateFromFirebaseTimestamp(dto.partyStartDate)
})


export const convertHoppingDtoToEntity = (dto: HoppingEventDto): HoppingEvent => {
    return {
        ...dto,
        saleEndDate: getDateFromFirebaseTimestamp(dto.saleEndDate),
        allocationDate: getDateFromFirebaseTimestamp(dto.allocationDate),
        eventDate: getDateFromFirebaseTimestamp(dto.eventDate),
        eventPublished: getDateFromFirebaseTimestamp(dto.eventPublished),
        afterPartyLocation: convertAfterPartyLocationDtoToEntity(dto.afterPartyLocation)
    }
}
