import { createMemoryHistory, createRouter, createWebHashHistory, createWebHistory } from "vue-router"
import routes from "@router/routes"
import { route } from "quasar/wrappers"

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function(/* { store, ssrContext } */) {
    const createHistory = process.env.SERVER ?
        createMemoryHistory :
        (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

    const Router = createRouter({
        scrollBehavior(to, _from, _savedPosition) {
            if (to.hash) {
                return {
                    el: to.hash,
                    behavior: 'smooth',
                }
            } else {
                return {
                    top: 0,
                    behavior: 'smooth',
                }
            }
        },
        routes,

        // Leave this as is and make changes in quasar.conf.js instead!
        // quasar.conf.js -> build -> vueRouterMode
        // quasar.conf.js -> build -> publicPath
        history: createHistory(process.env.VUE_ROUTER_BASE)
    })

    Router.beforeEach((_to) => {
    // @ts-ignore
    //     logEvent(analytics, 'screen_view', {
    //         firebase_screen: to.name ?? 'default_screen',
    //         firebase_screen_class: to.path ?? 'default_path'
    //     })
    })


    return Router
})
