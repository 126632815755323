import { AxiosPromise } from "axios"

import { DinnerOrder, DinnerOrderValidateResponse } from "@models/dto/dinnerOrder"

import { dinnerApi } from "@boot/axios"


export const createOrderApi = (order: DinnerOrder): AxiosPromise<DinnerOrderValidateResponse> => {
    return dinnerApi.post('/store/checkout',
        {
            order
        }
    )
}

export const approveOrderApi = (orderId: string, eventId: string): AxiosPromise<DinnerOrderValidateResponse> => {
    return dinnerApi.post('/store/checkout/approve',
        {
            orderId: orderId,
            eventId: eventId,
        }
    )
}

export const validateTeamApi = (order: DinnerOrder): AxiosPromise<DinnerOrderValidateResponse> => {
    return dinnerApi.post('/store/checkout', { order: order, step: 'TEAM' },)
}

export const validateAddressApi = (order: DinnerOrder): AxiosPromise<DinnerOrderValidateResponse> => {
    return dinnerApi.post('/store/checkout', { order: order, step: 'TEAM_AND_ADDRESS' },)
}

export const validateOrderApi = (order: DinnerOrder): AxiosPromise<DinnerOrderValidateResponse> => {
    return dinnerApi.post('/store/checkout', { order: order, step: 'ALL' },)
}

export const validateDiscountApi = (order: DinnerOrder, discountCode: string): AxiosPromise<DinnerOrderValidateResponse> => {
    return dinnerApi.post('/store/checkout', { order: order, step: 'ALL', discountCode: discountCode })
}
