import { AxiosPromise } from 'axios'

import { convertHoppingDtoToEntity } from '@api/converters/hoppingEvent.converter'

import { HoppingEventDto } from '@models/dto/hoppingEvent.dto'
import { Ticket } from '@models/dto/ticket.model'
import { HoppingEvent } from '@models/entities/hoppingEvent.model'

import { dinnerApi } from '@boot/axios'

export const getCurrentEventApi = async (): AxiosPromise<HoppingEvent> => {
    const event = await dinnerApi.get('/store/events/current')

    return event
}


export const getUpcomingEventsApi = async (): Promise<HoppingEvent[]> => {
    try {
        const response = await dinnerApi.get<HoppingEventDto[]>('/store/events/upcoming')

        return response.data.map((dto) => {
            return convertHoppingDtoToEntity(dto)
        })
    } catch (error) {
        console.error('Error in getUpcomingEventsApi:', error)
        throw error
    }
}

export const getCurrentTicketsApi = async (eventId: string): AxiosPromise<Ticket[]> => {
    const tickets = await dinnerApi.get(`/store/events/${eventId}/tickets`)

    return tickets
}

