import { captureException } from '@sentry/vue'
import { defineStore } from 'pinia'

import { getUpcomingEventsApi } from '@api/event.api'

import { FetchState } from '@constants/fetchState.enum'

import { Ticket } from '@models/dto/ticket.model'
import { HoppingEvent } from '@models/entities/hoppingEvent.model'

import { useDinnerOrderStore } from '@stores/dinnerOrder.store'

interface EventStoreState {
  fetchState: FetchState;
  event: HoppingEvent | null;
  upcomingEvents: HoppingEvent[];
  tickets: Ticket[] | null;
  registrationTimer: ReturnType<typeof setInterval> | null;
  dateNow: Date;
}

export const useEventStore = defineStore('event', {
    state: (): EventStoreState => {
        return {
            fetchState: FetchState.INIT,
            upcomingEvents: [],
            event: null,
            tickets: null,
            registrationTimer: null,
            dateNow: new Date()
        }
    },
    actions: {
        async init() {
            this.fetchState = FetchState.FETCHING
            try {
                const upcomingEvents = await getUpcomingEventsApi()

                this.fetchState = FetchState.SUCCESS

                if (upcomingEvents.length !== 0) {
                    await this.selectEvent(upcomingEvents[0])
                    this.upcomingEvents = upcomingEvents
                }

            } catch (error) {
                captureException(error)
                this.fetchState = FetchState.ERROR
            }
        },
        async selectEvent(selectedEvent: HoppingEvent) {
            this.event = selectedEvent
            this.tickets = selectedEvent.tickets

            this.setupTimer()

            useDinnerOrderStore().setEventId(selectedEvent._id)
            useDinnerOrderStore().setTickets(selectedEvent.tickets)
        },
        async selectEventByPath(path: string) {
            const eventFound = this.upcomingEvents.find((event) => event.uriPath === path)

            if (eventFound) {
                this.selectEvent(eventFound)
            }
        },
        async setupTimer() {
            if (this.registrationTimer) {
                clearInterval(this.registrationTimer)
                this.registrationTimer = null
            }

            this.registrationTimer = setInterval(() => {
                this.dateNow = new Date()
            },
            10000)
        }
    },
    getters: {
        isLoading: (state) => (state.fetchState === FetchState.FETCHING),
        isLoaded: (state) => (state.fetchState === FetchState.SUCCESS),
        partyLocation: (state) => {
            return state?.event?.afterPartyLocation.name ?? 'Afterparty Location'
        },
        isRegistrationOpen: (state) => {
            return (!!state.event) && state.event.saleEndDate > state.dateNow
        },
        displayEventSelectionStep: (state) => {
            return state.upcomingEvents.length > 1 || !state.event
        },
        partyTicketAvailable: (state) => {
            return state.tickets?.some((ticket) => ticket.type === 'OPTIONAL')
        }
    }
})
